import React from 'react';
import styled from 'styled-components';

const OverlayPanel = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0, 1);
  display: block;
  opacity: 0;
  transition: all .25s ease-in-out;
  pointer-events: none;
  z-index: 80;
  cursor: pointer;

  &.is-open {
    opacity: .75;
    pointer-events: auto;
  }

  &.cover-nav {
    z-index: 90;
  }

`;

class Overlay extends React.Component {
  render() {
    return (
        <OverlayPanel
          onClick={this.props.onClick}
          className={(`${this.props.isActive ? 'is-open' : ''} ${this.props.coverNav ? 'cover-nav' : ''}`)}
        />
    );
  }
}

export default Overlay;
