import React from 'react';
import styled from 'styled-components';

const DropdownBtn = styled.button`
    height: 48px;
    padding: 2px 16px;
    margin-top: 8px;
    color: #fff;
    width: 80%
    left: 10%;
    border: 0;
    right: auto;
    border-radius: 3px;
    font-size: 16px;
    position: absolute;
    text-align: center;
    display: block;
    cursor: pointer;
    background: #906A60;
    z-index: 50;


    @media (min-width: 425px) {
        width: auto;
        right: 40px;
        left: auto;
        text-align: left;
    }

    &:focus {
        outline: none;
    }

    & .icon {padding: 0 0 0 16px}

    & .no-icon {padding: 0!important}
`;

const DropdownPanel = styled.ul`
    margin: 0;
    padding: 16px;
    background: white;
    position: absolute;
    top: 100%;
    width: calc(100% + 4px);
    right: -2px;
    display: none;
    z-index: 50;
    border: 2px solid #B2938B;
    border-radius: 3px;

    &:focus {
        outline: none;
    }

    &.is-showing {
        display: block;
    }
    & li {
        list-style: none;
        margin: 0;
        padding: 22px 0;
        opacity: 1;
        background: #f6f6f6;

        @media (min-width: 425px) {
            padding: 8px 0;
            background: #fff;
        }

        a {
            color: #B2938B!important;
        }

        &.active-sort {
            color: #B2938B;
            font-weight: bold;
            opacity: .5;
            cursor: not-allowed!important;

            a {
                cursor: not-allowed!important;
            }

            &:after {
                content: " \f0d7";
                position: relative;
                font-family: "FontAwesome";
                padding-left: 8px;
                
            }
        }
    }

`;


class DropdownButton extends React.Component {
    constructor() {
        super();
        this.state = {
            showing: false
        }
    }

    handleClick() {
        this.setState({ showing: !this.state.showing })
    }


    render() {
      return (
        <DropdownBtn
            onClick={() => this.handleClick()}
        >
            {this.props.btnText}
            <span className={(`${this.props.btnIcon} btn-icon`)}></span>
            <DropdownPanel className={this.state.showing ? 'is-showing' : 'null'}>
                
                <li className={this.props.bySize ? 'active-sort' : ''}
                    onClick={this.props.sizeClick}><span className="custom-dropdown-buttons">sort by size</span>
                </li>

                <li className={this.props.byPrice ? 'active-sort' : ''}
                    onClick={this.props.priceClick}><span className="custom-dropdown-buttons">sort by price</span>
                </li>

                <li className={this.props.byName ? 'active-sort' : ''}
                    onClick={this.props.nameClick}><span className="custom-dropdown-buttons">sort by name</span>
                </li>

                <li className={this.props.byDate ? 'active-sort' : ''}
                    onClick={this.props.dateClick}><span className="custom-dropdown-buttons">sort by newest</span>
                </li>

            </DropdownPanel>
        </DropdownBtn>
      );
    }
  }
  
  export default DropdownButton;