import React from 'react';
import styled from 'styled-components';

const FooterEl = styled.div`
    position: absolute;
    height: 8px;
    background: #906A60;
    width: 100vw;
    margin-top: 40px;
    border: 0!important;
`;



const Footer = () => (
    <FooterEl></FooterEl>
)

export default Footer;