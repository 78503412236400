import React from 'react';
import styled from 'styled-components';

const Buttons = styled.button`
    line-height: 48px;
    padding: 0 24px;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    z-index: 10;

    &.primary {
        background: #B2938B;
        color: #fff;
        border: 0;
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
        transition: all .15s ease-in-out;
        cursor: pointer;
        
        &:hover {
            background: #906A60;
        }
    }

    &.secondary {
        color: #B2938B;
        cursor: pointer;
        transition: all .15s ease-in-out;

        &.margin-right {
            margin-right: 32px!important;
        }

        &:hover {
            color: #906A60;
        }
    }

    &.tertiary {
        color: #fff;
        background: pink;
        cursor: pointer;
        transition: all .15s ease-in-out;

        &.margin-right {
            margin-right: 32px!important;
        }

        &:hover {
            color: #906A60;
        }

    &:focus {
        outline: none;
    }
`;


const Button = ({btnText, btnIcon, onClick, btnClass, name, type}) => (
    <Buttons className={btnClass} onClick={onClick} name={name} type={type}>
        {btnText}
        <span className={btnIcon}></span>
    </Buttons>
)

export default Button;