import React from 'react';
import styled from 'styled-components';

const DesignerInformation = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% + 32px);
    margin-left: -16px;
    background: white;
    position: absolute;
    bottom: 24px;
    border-radius: 3px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    cursor: pointer;

    @media screen and (max-width: 320px) {
        width: 100%;
        margin-left: 0;
        bottom: 0;
        position: relative;
      }

    & .text {
        margin-left: 32px;
        text-align: left;

        @media screen and (max-width: 320px) {
            margin-left: 4px;
            padding: 6px 10px;
          }
    }

    & h3, p {
        margin: 0;
    }
`;

const Icon = styled.div`
    background: #B2938B;
    height: 100%;
    display: block;
    padding: 20px 24px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    @media screen and (max-width: 320px) {
        display: none;
      }
    
    & span {
        transition: all .15s ease-in-out;
        color: #906A60;
        font-size: 26px;

        @media screen and (max-width: 320px) {
            display: none;
          }
    }
`;
class DesignerCard extends React.Component {
    render() {

    const DesignerCards = styled.div`
        position: relative;
        border-radius: 4px;
        width: 100%;
        margin: 24px;

          @media screen and (min-width: 760px) {
            width: 33%;
            padding-bottom: 50px;
          }

          @media screen and (min-width: 1185px) {
            width: 29%;
            padding-bottom: 50px;
          }
        
        & img {
            display: flex;
            width: 100%;
            height: auto;
            box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
            border-radius: 4px;
            z-index: 0;
            position: relative;
            transition: all 5s ease-in-out;
            cursor: pointer;
        }
    `;

      return (
        <DesignerCards onClick={this.props.onClick}>
                 <img src={this.props.bg} alt={`Wedding dress designer named ${this.props.title}`} />
                <DesignerInformation onClick={this.props.onClick}>
                    <div className="text">
                        <h3>{this.props.title}</h3>
                        {/* <span className="subtext">{this.props.dresses}</span> */}
                    </div>
                    <Icon><span className="fa fa-arrow-right"></span></Icon>
                </DesignerInformation>
        </DesignerCards>
      );
    }
  }
  
  export default DesignerCard;
