import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
    display: flex;
    align-items: center;
    margin: 0 8px;
    // margin-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 4px;

    h3 {
        margin: 0;
        padding: 0;
    }

    &:hover {
        background: #f6f6f6;
        cursor: pointer;
    }
`;

const TextContainer = styled.div`
    margin-left: 8px;
`;

const Thumbnail = styled.div`
    background-image: ${props => props.thumbnail ? props.thumbnail : ""};
    background-position: center center;
    background-size: contain;
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 4px;
    margin: 8px;
`;

const searchResultCard = ({name, thumbnail, className, altText, designer, onMouseDown}) => (
    <Card onMouseDown={onMouseDown} className={className}>
        <Thumbnail thumbnail={thumbnail} alt={altText}></Thumbnail>
        <TextContainer>
            <p>{designer}</p>
            <h3>{name}</h3>
        </TextContainer>
    </Card>
)

export default searchResultCard;