import React from 'react';
import Data from '../assets/data/designer-data.json';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Overlay from '../components/Overlay';
import DropdownButton from '../components/DropdownButton';
import DressCard from '../components/DressCard';
import Slider from "react-slick";
import ReactGA from 'react-ga'
import Toast from './Toast';

export const initGA = () => {
  ;
  ReactGA.initialize('UA-128840410-1');
}
export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname + window.location.search});
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const DressContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
`;

const SliderDressInfo = styled.span`
  position: relative;
  display: inline-block;
  bottom: 28px;
  left: 12px;
  border-radius: 4px;
  background: white;
  text-align: left;
  padding: 10px 18px;
  z-index: 500;
  
`;

const SliderContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  
  &.is-active {
    display: flex;
  }
`;

class SampleSale extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        isActive: false,
        byName: false,
        bySize: false,
        byPrice: false,
        byDate: false,
        saleDresses: [],
        saleDress: {},
        btnText: 'Sort dresses',
        sliderContent: []
    }
  }

  componentDidMount() {
    initGA()
    logPageView()
  };

  componentWillMount() {
    const dress = this.props.match.params.dress;
    this.updateDesigner(dress);
  }

  updateDesigner(dress) {
    let filteredSaleDresses = [];
    // eslint-disable-next-line
    Data.forEach((designer) => {
      designer.dresses.filter((saleDress) => {
        if (saleDress.sale === true) {
          filteredSaleDresses.push(saleDress);
        }
      });
    });
    this.setState({ saleDresses: filteredSaleDresses }, () => {
      this.sortByDate();
      this.openDressInModal(dress);
    });
  }

openDressInModal(dress) {
    if (dress) {
      this.state.saleDresses.map((saleDress, key) => {
        if (saleDress.name === dress) {
          this.handleImageClick(
            saleDress.images,
            saleDress.name,
            saleDress.sale,
            saleDress.salePrice,
            saleDress.price,
            saleDress.size,
            saleDress.budget,
            saleDress.budgetDetails
          );
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const dress = nextProps.match.params.dress

    this.updateDesigner(dress);
  }

    handleScrolling() {
        // this.state.isActive ? document.body.style.height = "100%" : document.body.style.height= "auto";
        // this.state.isActive ? document.body.style.overflowY = "hidden" : document.body.style.overflowY= "scroll";
        // this.state.isActive ? document.documentElement.style.overflow = "hidden" : document.documentElement.style.overflow = "scroll";
      }

      CloseSlider() {
        if (this.slider) this.slider.slickGoTo(0, true);
        this.setState({ isActive: !this.state.isActive}, ()=> {this.handleScrolling()});
      }

    sortNumbers(a,b) {
      return a - b
    }

    sortBySize() {
        let sortedDresses = this.state.saleDresses.sort(function(a,b) {return (a.size > b.size) ? 1 : ((b.size > a.size) ? -1 : 0);} );
        this.setState({
            saleDresses: sortedDresses,
            bySize: true,
            byName: false,
            byPrice: false,
            byDate: false,
            btnText: "Dresses Sorted by Size"
        })
    }

    sortByDate() {
        let sortedDresses = this.state.saleDresses.sort(function(a,b) {return b.date - a.date;} );
        this.setState({
            saleDresses: sortedDresses,
            bySize: false,
            byName: false,
            byPrice: false,
            byDate: true,
            btnText: "Dresses Sorted by Newest"
        })
    }

    sortByPrice() {
        let sortedDresses = this.state.saleDresses.sort((a,b) => a.salePrice - b.salePrice);
        this.setState({
            saleDresses: sortedDresses,
            byPrice: true,
            byName: false,
            bySize: false,
            byDate: false,
            btnText: "Dresses Sorted by Price"
        })
    }

    sortByName() {
        let sortedDresses = this.state.saleDresses.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        this.setState({
            saleDresses: sortedDresses,
            byName: true,
            bySize: false,
            byPrice: false,
            byDate: false,
            btnText: "Dresses Sorted by Name"
        })
    }

    handleOverlayClick() {
        if (this.slider) this.slider.slickGoTo(0, true);
        this.setState({ isActive: !this.state.isActive}, ()=> {this.handleScrolling()});
      }

      handleDressClick(d) {
        this.props.history.push({
            pathname: `/samplesale/${d.name}`,
            search: ``,
            params: {
                dress: d.name
            }
        });
    }

    handleImageClick(images, name, sale, salePrice, price, size) {
      this.setState({
        isActive: !this.state.isActive,
        sliderContent: images,
        slideName: name,
        slideSale: sale,
        slideSalePrice: salePrice,
        slidePrice: price,
        slideSize: size
      }, ()=> {this.handleScrolling()});
    }
    
    getBudgetDetails() {
      if(this.state.slideSalePrice) {
        return <span className="subtext">{this.state.slideSize} {this.state.slidePrice} now £{this.state.slideSalePrice}</span>
      } else if (this.state.budget) {
        return this.state.budgetDetails.map((item, i) =>
        <div className="subtext">{`UK${item.size}: ${item.price}`}</div>)
      }
    }

  render() {

    const settings = {
        dots: true,
        infinite: true,
        fade: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
      };

  return (
    <Layout theme="dark" stickyNav="sticky-nav" sticky="true">
      <Toast></Toast>
        <Overlay isActive={this.state.isActive} onClick={() => this.handleOverlayClick()} />

        <SliderContainer className={this.state.isActive ? 'is-active' : ''}>
          <Slider ref={slider => this.slider = slider}{...settings}>
            {this.state.sliderContent.map((d, id) =>
              <div key={id}>
                <img className="image-slide" src={d.path} alt={`Wedding dress named ${this.state.slideName} by ${this.state.title}`} />
                
                <SliderDressInfo>
                  <strong>{this.state.slideName}</strong><br />
                  {this.getBudgetDetails()}

                </SliderDressInfo>

              </div>
            )}
          </Slider>
        </SliderContainer>

        <div className="sale-sort-button-spacing page-vs-nav">
            <h1 className="script">Sample Sale</h1>
            <p>We edit our dress range regularly and we are constantly taking deliveries of new styles so we like to discount our stunning dresses. A sample gown may be the answer if you want a designer dress to fit your budget.</p>
            <DropdownButton
                btnClass="dropdown-btn"
                btnText={this.state.btnText}
                btnIcon="icon fa fa-caret-down"
                sizeClick={() => this.sortBySize()}
                priceClick={() => this.sortByPrice()}
                nameClick={() => this.sortByName()}
                dateClick={() => this.sortByDate()}
                byName={this.state.byName}
                byPrice={this.state.byPrice}
                bySize={this.state.bySize}
                byDate={this.state.byDate}
            />
        </div>
        <div className="main-body">
            <DressContainer>
                {this.state.saleDresses.map((d, id) => 
                    <DressCard
                        key={id}
                        onSale={d.sale}
                        bg={d.images[0].path}
                        name={d.name}
                        colour={d.colour}
                        size={d.size}
                        price={d.price}
                        salePrice={d.salePrice}
                        onClick={() => this.handleDressClick(d)}
                    />
                )}</DressContainer>
        </div>
    </Layout>
  );
  }
}

export default SampleSale;
