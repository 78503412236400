import React from 'react';
import styled from 'styled-components';
import Data from '../assets/data/designer-data.json';
import SearchResultCard from '../components/SearchResultCard';
import { withRouter } from 'react-router-dom';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-slef: end;
    position: absolute;
    top: 10px;
    right: 88px;
    width: 300px;
    background: white;
    padding: 0 4px;
    border-radius: 4px;
    transition: all .25s ease-in-out;

    &.active-radius {
        border-radius: 4px 4px 0 0;
    }

    @media screen and (max-width: 640px) {
        display: block;
        width: 90%;
        background: white;
        left: 5%;
      }

`;

const Results = styled.div`
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
    display: block;
    background: white;
    border-radius: 0 0 4px 4px;
    -webkit-box-shadow: 0px 0px 10px 1px #00000040; 
    box-shadow: 0px 0px 10px 1px #00000040;
    z-index: 1000;

    & p {
        margin: 0;
        padding: 0;
    }
`;

const NumberOfResults = styled.span`
    position: absolute;
    line-height: 40px;
    right: 4px;
    color: #aaa;
    font-size: 12px;
    opacity: 0;
    margin-top: -8px;
    transition: all .4s ease-in-out;

    @media screen and (max-width: 640px) {
        top: 0;
      }

    &.focused {
        margin-top: 0;
        opacity: 1;
    }
`;

const SearchInput = styled.input`
    display: block;
    width: 100%;
    margin: 0 auto;
    border: 0;
    line-height: 40px;
    background: white;
    padding: 0;
    
    &:focus {
        outline: none;
    }

    &.mobile {
        border: 1px #333 solid;
    }

`;

class Search extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isActive: false,
            searchTerm: 'e',
            active: false,
            numberOfResults: 0,
            searchResults: [],
            resultCounterText: 'results'
        }
    }

    resetSearch() {
        document.getElementById('search-input').value = '';
        this.setState({searchTerm: ''});
        this.setState({searchResults: []});
        this.setState({numberOfResults: 0});
        this.setState({active: false});
        document.getElementById('number-of-results').classList.remove('focused');
    }

    handleOnBlur() {
        this.resetSearch()
    }

    

    handleResultsClick(d) {
        this.props.history.push({
            pathname: `/designer/${d.designer}/${d.name}`,
            search: ``,
            params: {
                designer: d.designer.title,
                dress: d.name
            },
            state: {
                //From search
                searchedImages: d.images,
                dressName: d.name,
                sale: d.sale,
                price: d.price,
                salePrice: d.salePrice,
                size: d.size,
                fromSearch: true
            }
        });
        // this.resetSearch()
        window.scrollTo(0,0);
    }

    handleFocus() {
        document.getElementById('number-of-results').classList.add('focused');
        this.setState({active: true});
    }

    handleSearchInputChange(e) {
        let searchTerm = document.getElementById('search-input').value;
        let searchResults = [];
            Data.forEach(designer => {
                designer.dresses.filter((dresses) => {
                    if(searchTerm === "") {
                        searchResults = [];
                    }
                    else if(dresses.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                        searchResults.push({...dresses, designer: designer.title})
                    }
                })
            })
            this.setState({searchResults: searchResults})
            this.setState({numberOfResults: searchResults.length})
        }



        

    render() {

        return (
            <div>
                <Wrapper id="search-container" className={`${this.state.active ? "active-radius" : ""}`}>
                    <SearchInput
                        autoComplete="off"
                        onFocus={() => this.handleFocus()}
                        onBlur={() => this.handleOnBlur()}
                        id="search-input"
                        placeholder="Search dresses..."
                        onChange={(e) => this.handleSearchInputChange(e)}
                        />
                        <NumberOfResults id="number-of-results">{this.state.numberOfResults} {this.state.resultCounterText}</NumberOfResults>
                    <Results className={`${this.props.mobile ? "mobile" : ""} search-result`}>
                        {this.state.searchResults.map((d, key) =>
                            <SearchResultCard
                                key={key}
                                thumbnail={`url('${d.images[0].path}')`}
                                name={d.name}
                                designer={d.designer}
                                onMouseDown={() => this.handleResultsClick(d)}
                            />
                        )}
                    </Results>
                </Wrapper>
                
            </div>
        )
    }
  }

export default withRouter(Search);