import React from 'react';
import styled from 'styled-components';
import Data from '../assets/data/designer-data.json';
import { withRouter } from 'react-router-dom';
import Layout from '../components/Layout';
import Overlay from '../components/Overlay';
import DressCard from '../components/DressCard';
import Slider from "react-slick";
import ReactGA from 'react-ga'
import Toast from './Toast';

export const initGA = () => {

  ReactGA.initialize('UA-128840410-1')
}
export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname + window.location.search })
  ReactGA.pageview(window.location.pathname + window.location.search)
}

const SliderDressInfo = styled.span`
  position: relative;
  display: inline-block;
  bottom: 28px;
  left: 12px;
  border-radius: 4px;
  background: white;
  text-align: left;
  padding: 10px 18px;
  z-index: 500;
  }
  
`;

const SliderContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  
  &.is-active {
    display: flex;
  }
`;

const DressContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
`;

class Designer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      title: '',
      blurb: '',
      sale: false,
      budget: false,
      sortKey: [],
      dresses: [],
      sliderContent: [],
      slideName: '',
      slideSale: false,
      slidePrice: '',
      slideSize: '',
      budgetDetails: [],
      slideSalePrice: '',
      slideIndex: 0,
      updateCount: 0,
      searchReset: true
    }
  }

  componentDidMount() {
    initGA()
    logPageView()
  }

  updateDesigner(designer, dress) {
    if (designer && dress) {

      Data.map((d, key) => {
        if (d.title.toLowerCase().split(' ').join('') === designer.toLowerCase().split(' ').join('')) {

          this.setState({
            title: d.title,
            blurb: d.blurb,
            sale: d.sale,
            budget: d.budget,
            budgetDetails: d.budgetDetails,
            dresses: d.dresses,
            sortKey: d.sortKey
            }, () => {
              this.orderDresses();

            const dressToFind = d.dresses.find(dressObj => {
              return dressObj.name === dress
            });

            this.handleImageClick(
              dressToFind.images,
              dressToFind.name,
              dressToFind.sale,
              dressToFind?.salePrice,
              dressToFind?.price,
              dressToFind?.size,
              dressToFind?.budget,
              dressToFind?.budgetDetails
            )
          });
        }

      })} else if(designer && !dress) {
        
        Data.map((d, key) => {
          if (d.title.toLowerCase().split(' ').join('') === designer.toLowerCase().split(' ').join('')) {

            this.setState({
              title: d.title,
              blurb: d.blurb,
              sale: d.sale,
              budget: d.budget,
              budgetDetails: d.budgetDetails,
              dresses: d.dresses,
              sortKey: d.sortKey
            }, () => {
              this.orderDresses();
            });

            }
          });

      }
  }

  componentWillMount() {
    const designer = this.props.match.params.designer
    const dress = this.props.match.params.dress
    this.updateDesigner(designer, dress);
  }

  componentWillReceiveProps(nextProps) {
    const designer = nextProps.match.params.designer
    const dress = nextProps.match.params.dress

    this.updateDesigner(designer, dress);
  }

  orderDresses() {
    if (this.state.sortKey.length) {
      this.setState({
        dresses: this.state.dresses.sort((a, b) => {
          let aIndex = this.state.sortKey.indexOf(a.name[0]);
          let bIndex = this.state.sortKey.indexOf(b.name[0]);
          if (aIndex === -1) aIndex = this.state.sortKey.length
          if (bIndex === -1) bIndex = this.state.sortKey.length

          return (a.sale - b.sale) || (aIndex - bIndex) || a.name.localeCompare(b.name)
        })
      })
    } else {
      this.setState({
        dresses: this.state.dresses
      })
    }
  }

  handleScrolling() {
    // this.state.isActive ? document.body.style.height = "100%" : document.body.style.height = "auto";
    // this.state.isActive ? document.body.style.overflowY = "hidden" : document.body.style.overflowY = "scroll";
    // this.state.isActive ? document.documentElement.style.overflow = "hidden" : document.documentElement.style.overflow = "scroll";
  }

  handleDressClick(d) {
    this.props.history.push({
        pathname: `/designer/${this.state.title}/${d.name}`,
        search: ``,
        params: {
            designer: this.state.title,
            dress: d.name
        }
    });
}

  handleImageClick(images, name, sale, salePrice, price, size, budget, budgetDetails) {
    this.setState({
      isActive: !this.state.isActive,
      sliderContent: images,
      slideName: name,
      slideSale: sale,
      slideSalePrice: salePrice,
      slidePrice: price,
      budget: budget,
      budgetDetails: budgetDetails,
      slideSize: size
    }, () => { this.handleScrolling() });
  }

  handleOverlayClick() {
    if (this.slider) this.slider.slickGoTo(0, true);
    this.setState({ isActive: !this.state.isActive }, () => { this.handleScrolling() });

    this.props.history.push({
      pathname: `/designer/${this.state.title}`,
      search: ``,
      params: {
          designer: this.state.title,
          dress: null
      }
  });


  }

  getBudgetDetails() {
    if (this.state.slideSale) {
      return <span className="subtext">{this.state.slideSize} {this.state.slidePrice} now £{this.state.slideSalePrice}</span>
    } else if (!this.state.slideSale) {
      return <span className="subtext">{this.state.slideSize} {this.state.slidePrice}</span>
    }
  }

  render() {

    const settings = {
      dots: true,
      infinite: false,
      fade: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      focusOnSelect: true,
      afterChange: () =>
        this.setState(state => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next })
    };

    return (
      <Layout theme="dark" stickyNav="sticky-nav" sticky="true">
        <Toast></Toast>
        <Overlay isActive={this.state.isActive} onClick={() => this.handleOverlayClick()} />
        <SliderContainer className={this.state.isActive ? 'is-active' : ''}>
          <Slider ref={slider => this.slider = slider}{...settings}>
            {this.state.sliderContent.map((d, id) =>
              <div key={id}>
                <img className="image-slide" src={d.path} alt={`Wedding dress named ${this.state.slideName} by ${this.state.title}`} />

                <SliderDressInfo>
                  <strong>{this.state.slideName}</strong><br />
                  {this.getBudgetDetails()}
                </SliderDressInfo>
              </div>
            )}
          </Slider>
        </SliderContainer>

        <div className="main-body page-vs-nav">

          <h1 className="script">{this.state.title}</h1>
          <p>{this.state.blurb}</p>
          <DressContainer>
            {this.state.dresses.map((d, id) =>
              <DressCard
                key={id}
                onSale={d.sale}
                bg={d.images[0].path}
                name={d.name}
                size={d.size}
                price={d.price}
                salePrice={d.salePrice}
                budget={d.budget}
                budgetDetails={d.budgetDetails}
                onClick={() => this.handleDressClick(d)} />
            )}
          </DressContainer>
        </div>
      </Layout>
    );
  }
}

export default withRouter(Designer);