import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import OurDesigners from './components/OurDesigners';
import Homepage from './components/Homepage';
import Designer from './components/Designer';
import Appointments from './components/Appointments';
import SampleSale from './components/SampleSale';
import Contact from './components/Contact';
import ReactGA from 'react-ga'

export const initGA = () => {
  ;
  ReactGA.initialize('UA-128840410-1');
}
export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname + window.location.search});
  ReactGA.pageview(window.location.pathname + window.location.search);
}

class App extends React.Component {

  componentDidMount () {
    initGA()
    logPageView()
  }

  render() {

    return (
      <BrowserRouter>
        <React.Fragment>
          <Route exact path="/" component={Homepage} />
          <Route path="/ourdesigners" component={OurDesigners} />
          <Route path="/designer/:designer/:dress?" component={Designer} />
          <Route path="/appointments" component={Appointments} />
          <Route path="/samplesale/:dress?" component={SampleSale} />
          <Route path="/contact" component={Contact} />
        </React.Fragment>
      </BrowserRouter>
      
    );
  }
}

export default App;
