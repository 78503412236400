import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Search from '../components/Search';
import Modal from './ClosingModal';
// import Banner from '../components/Banner';


const NavContainer = styled.div`
    background: transparent;
    width: 100%;
    height: 60px;
    padding: 0 40px;
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 80;

    @media screen and (max-width: 640px) {
        padding: 16px 40px;
        height: 120px;
        align-items: flex-end;

    }

    &.sticky {
        position: fixed;
        background-color: ${props => props.bgColor ? props.bgColor : "#F6F6F6"};
    }
`;

const Logo = styled.span`
    text-transform: uppercase;
    font-family: Niconne;
    font-size: 32px;
    color: white;

    &.dark {
        color: #906A60;
    }
`;

const LayoutContainer = styled.div`
    width: 100vw;
    overflow-x: hidden!important;
`;

class Layout extends React.Component {
    constructor(props){
        super(props);
        this.state={
            active: false
        }
    }

    handleAppointment() {
        this.setState({ active: !this.state.active });
    }

  render() {
    return (
        <React.Fragment>
            <LayoutContainer>
                {/* <Banner></Banner> */}
                <Modal></Modal>
                <NavContainer bgColor={this.props.bgColor} className={`${(this.props.sticky ? 'sticky' : '')} ${this.props.stickyNav}`} >
                    <NavLink className="no-underline" to="/"><Logo className={this.props.theme}>Amorae</Logo></NavLink>
                    <Search mobile={false} />
                    <Navigation onClick={() => this.handleAppointment()} theme={this.props.theme} />
                </NavContainer>
                {this.props.children}
                <Footer />
            </LayoutContainer>
        </React.Fragment>
    );
  }
}

export default Layout;
