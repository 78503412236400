import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Button from '../components/Button';
import Overlay from '../components/Overlay';
import { NavLink } from 'react-router-dom';
import heroImage from '../assets/images/hero.jpg';
import heroWaveImage from '../assets/images/hero-wave-white.svg';
import shopImage from '../assets/images/amorae-shop.jpg';
import ReactGA from 'react-ga';
import ClosingModal from '../components/ClosingModal'
import Toast from './Toast';

export const initGA = () => {
  
  ReactGA.initialize('UA-128840410-1')
}
export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname + window.location.search})
  ReactGA.pageview(window.location.pathname + window.location.search)
}

const Hero = styled.div`
  background-image: url(${heroImage});
  background-size: cover;
  background-position: top center;
  width: 100%;
  height: 60vh;
  position: relative;
  margin-top: -60px;

  @media (min-width: 1100px) {
    height: 75vh;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%
    height: 100%
    background: -moz-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
  }
`;

const ImageContainer = styled.div`
  border-radius: 4px;
  display: block;
  position: relative;
  z-index: 10;

  & img {
    width: 100%;
    height: auto;
    border-radius: 4px;
    margin: 32px 0;

    @media (min-width: 920px) {
      width: 100%;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    
  }

  &::before {
      content: '';
      position: absolute;
      top: calc(50% - 50% / 2);
      left: -20%;
      width: 125%;
      height: 50%;
      background: #B2938B;
      z-index: -1;

      @media (min-width: 920px) {
        width: 126%;
        right: -20%;
        left: -7%;
        border-top-left-radius: 150px;
        border-bottom-left-radius: 150px;
      }
  }
`;

const Quote = styled.i`
  text-align: center!important;
  margin: 0 auto;
  font-weight: bold;
`;

const HeroCtaContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -100px;
`;

class Homepage extends React.Component {
  constructor(){
    super();
    this.state = {
      isActive: false,
      showClosingModal: false
    }
}

goToTop() {
  window.scrollTo(0,0);
}

handleBooking() {
  this.goToTop();
  this.handleClick();
  
}

handleClick() {
    this.setState({isActive: !this.state.isActive})
}

closeModalClick() {
  window.sessionStorage.setItem("showClosingModal", false);
  this.setState({ showClosingModal: !this.state.showClosingModal});
}

readMessageClick() {
  window.sessionStorage.setItem("showClosingModal", true);
  this.setState({ showClosingModal: !this.state.showClosingModal});
}

componentDidMount () {
  let storageValue = window.sessionStorage.getItem("showClosingModal");
  if (!storageValue) {
    this.setState({showClosingModal: true});
  }
  initGA()
  logPageView()
}

  render() {
    return (
      <React.Fragment>
        <Layout sticky="true" bgColor="#22222250">
          <Toast />
          <Overlay isActive={this.state.isActive} onClick={() => this.handleClick()} />
          <ClosingModal
            onClick={() => this.closeModalClick()}
            showModal={this.state.showClosingModal}
          />
          <Hero>
            <img className="hero-wave" src={heroWaveImage} alt="" />
          </Hero>
          <HeroCtaContainer>
            <NavLink className="no-underline" to="/ourdesigners">
              <Button
                btnClass="primary hero-cta"
                btnText="Our Designers"
                btnIcon="icon fa fa-arrow-right" />
            </NavLink>
          </HeroCtaContainer>
          <div className="flex-container">
          <section className="main-body col-50">
            <ImageContainer>
                <img src={shopImage} alt="Inside the Amorae Bridal boutique"/>
            </ImageContainer>
          </section>
          <section className="main-body col-50">
            <h2 className="tab script">About Amorae</h2>
            <Quote>We're an award winning bridal shop in Essex.</Quote>

            <p>We are located in the historic market town of Ongar, Essex. Approximately 6 miles North of Brentwood, 9 miles to the West of Chelmsford and 7 miles East of Harlow, with transport links via the A414 and the M11.</p>

            <p>The shop is a stunning 17th Century building that looks small from the outside, however we have over 350 Bridal and 200 Prom dresses to choose from.</p>

            <p>From the moment you step into Amorae you can expect a warm welcome from our friendly, experienced staff. We offer a one-to-one service so the bride can have the privacy of the shop to herself to choose the most important dress she will ever wear. From the simple and elegant to contemporary and statement designs, our extensive selection of dresses have been carefully chosen from the industries top designers.</p>

            <p>You are welcome to browse at any time. If you want to try on gowns please <NavLink className="a" to="/appointments">request an appointment</NavLink>, so we can guarantee that you are given our full attention; we really wouldn't want it any other way!</p>

            <p>Look forward to meeting you soon,</p>

            <p><b>Amorae will be closing its doors in 2024 - <span className="a" onClick={() => this.readMessageClick()}>read our closing message</span></b></p>
            
            <p>Emmalee, Leanne, Gill and Janis</p>
          
          </section>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default Homepage;