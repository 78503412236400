import React from 'react';
import styled from 'styled-components';


const Overlay = styled.div`
    display: block;
    z-index: 1000000;
    width 100%;
    height: 100%;
    Position: fixed;
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(20px);
    opacity: .9;

    &.is-hidden {
        visability: hidden;
        pointer-events: none;
        opacity: 0;
    }
`;

class ClosingModalOverlay extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isHidden: false
        }
    }

    render() {
        return (
            <Overlay className={this.props.showModal}></Overlay>
        )
    }
}


export default ClosingModalOverlay;