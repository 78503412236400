import React from "react";
import styled from 'styled-components';

const SaleBadgeIcon = styled.div`
    display: none;
    position: absolute;
    z-index: 10;
    background: #F5455A;
    padding: 8px 8px;
    border-radius: 3px;
    top: 32px;
    right: -10px;

    &.banner {
      display: inline-block;
      position: relative;
      top: 0;
      right: 0;
      margin-right 16px;

      @media screen and (max-width: 760px) {
        margin-right: 0;
        margin-bottom: 4px;
    }

    }
    
    &.is-showing {
        display: inline-block;
    }

    & h4 {
        margin: 0;
        padding: 0;
        color: white;
    }
`;


class SaleBadge extends React.Component {
  render() {

    return (
          <SaleBadgeIcon className={`${this.props.onSale ? 'is-showing' : ''} ${this.props.class}`}>
                <h4>{this.props.text}</h4>
          </SaleBadgeIcon>
    );
  }
}

export default SaleBadge