import React from 'react';
import styled from 'styled-components';
import facebookIcon from '../assets/images/facebook-icon.svg';
import twitterIcon from '../assets/images/twitter-icon.svg';
import instagramIcon from '../assets/images/instagram-icon.svg';

const Container = styled.div`
    margin-top: 40px;
`;

const SocialMediaItem = styled.span`
    padding: 0 16px;
    width: 24px;
    heightL 24px;
    cursor: pointer;
    transform: scale(1,1);
    opacity: .7;
    
    &:hover {
        // transition: all .25s ease-in-out;
        opacity: 1;
    }
`;

class SocialMedia extends React.Component {

    render(){    
        return(
            <Container>

                <SocialMediaItem>
                    <a className="no-underline" rel="noopener noreferrer" target="_blank" href="https://facebook.com/profile.php/?id=61553121568097"><img src={facebookIcon} alt=""/></a>
                </SocialMediaItem>

                <SocialMediaItem>
                    <a className="no-underline" rel="noopener noreferrer" target="_blank" href="https://twitter.com/amoraebridal"><img src={twitterIcon} alt=""/></a>
                </SocialMediaItem>

                <SocialMediaItem>
                    <a className="no-underline" rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/amoraebridal/"><img src={instagramIcon} alt=""/></a>
                </SocialMediaItem>
                
            </Container>

        )
    }
}



export default SocialMedia
