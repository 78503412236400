import React from 'react';
import styled from 'styled-components';

const CheckBoxWrapper = styled.div`
    display: inline-block;
    margin-right: 16px;

    & input {
        position: relative;
        width: auto;
        margin: 0;
        padding: 0;
        
    }

    & .customCheck {
        display: inline-block;
        min-width: 64px;
        position: relative;
        color: #6C6C6C;
        padding: 13px 14px;
        border: 1px solid #B2938B;
        border-radius: 3px;
        font-size: 16px;
        text-align: center;
        

        &.checked {
            background: #B2938B;
            font-weight: bold;
            color: white;
        }
    }

`;

class Checkbox extends React.Component {

    render() {
        return (
            <CheckBoxWrapper>
                    <label className={`${this.props.isChecked ? 'checked' : ''} customCheck`}>
                        <input
                            type="radio"
                            index={this.props.index}
                            name={this.props.name}
                            id={this.props.id}
                            onChange={this.props.onChange}
                            onClick={this.props.onClick}
                            defaultValue={this.props.value}
                            hidden
                        />
                        {this.props.displayValue}
                    </label>
            </CheckBoxWrapper>
        )
    }
  }

export default Checkbox;