import React from 'react';
import styled from 'styled-components';
import Data from '../assets/data/designer-data.json';

const DressCounterText = styled.span`
    color: #6C6C6C
    font-size: 14px
    font-style: italic
`;

class DressCounter extends React.Component {
  constructor(){
    super();
    this.state = {
      dresses: 0,
      saleDresses: 0,
      text: 'Dresses',
      saleText: 'Sale Dresses'
    }
  }

  sale = (id) => {
    let saleDresses = [];

      for(let i=0; i < Data[id].dresses.length; i++) {
        saleDresses.push(Data[id].dresses[i].sale)
      }

      let saleFiltered = saleDresses.filter(dress => dress === true);
      this.setState({saleDresses: saleFiltered.length});
  }

  componentWillMount() {
    let dressCount = Data[this.props.id].dresses.length;

    dressCount === 1 ? this.setState({text: 'Dress'}) : this.setState({text: 'Dresses'});
    this.setState({dresses: dressCount});

    this.sale(this.props.id) === 1 ? this.setState({saleText: 'Sale Dress'}) : this.setState({SaleText: 'Sale Dresses'});
    return this.sale(this.props.id)
  }

  render(){
    
      return(
        <DressCounterText>
            {this.state.dresses} {this.state.text} | {this.state.saleDresses} {this.state.saleText}
        </DressCounterText>
      )
  }
}

export default DressCounter
