import React from 'react';
import styled from 'styled-components';
import ClosingModalOverlay from './ClosingModalOverlay';
import Button from './Button';

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;  
`;

const Modal = styled.div`
    display: block;
    z-index: 2000000;
    position: fixed;
    left: 20%;
    top: 56px;
    background: white;
    width: 60%;
    height: auto;
    max-height: 90%;
    overflow: scroll;
    opacity: 1;
    border-radius: 8px;
    padding: 24px;

    &.is-hidden {
        display: block;
        position: fixed;
        visability: hidden;
        pointer-events: none;
        opacity: 0;
    }

    @media (max-width: 1000px) {
        width: 90%;
        left: 5%;
      }
    
`;

const Quote = styled.i`
  text-align: center!important;
  margin: 0 auto;
  font-weight: bold;
  color: grey;
`;


class ClosingModal extends React.Component {

    constructor(props) {
        super(props);
    }

    componentWilllMount() {
        let modalIsShowing = window.sesstionStorage.getItem("modalDismissed");
        modalIsShowing ? '' : this.setState({isShowing: false});
    }

    render() {
        return (
            <React.Fragment>
            <ClosingModalOverlay showModal={this.props.showModal ? '' : 'is-hidden'}/>
            <Modal className={this.props.showModal ? '' : 'is-hidden'}>
                <h2 className="script">Retirement Sale</h2>
                <Quote>Its time to say goodbye</Quote>
                    
                    <p>Amorae is closing its doors as a wedding dress retailer.
                    We will continue to take orders for 2024 brides for a limited time and remain open until all our brides who have purchased their dress with us have had their alteration fittings.
                    We are having a Retirement sale to clear our huge amount of wedding dresses and we are selling our discontinued samples for half price or less.</p>
                    
                    <p>Current sample dresses will also be considerably reduced.
                    All our sample dresses can be viewed on our website and all stock must go. <b>Prom dresses are half price</b>. Occasion dresses are greatly reduced and must go.</p>
                    
                    <p>There is a <b>selection of sample bridesmaids dresses that are £40 or less</b> but we only have one of each style.
                    There is also a 10% discount on all new orders.</p>
                    
                    <p>We have made many friends helping brides to find ‘The dress’ and we are extremely grateful to our loyal customers who have recommended us to their friends and family allowing us to trade successfully for over 12 years.</p>
                    
                    <p>We would also like to thank all our Suppliers for their support and lastly a big thank you to the lovely people of Chipping Ongar who made us feel very welcome right from the beginning.</p>
                    
                    <p>Lots of love,</p>
                    <p>Janis Emmalee and Macie x</p>
                    <ButtonContainer>
                        <Button
                            btnClass="secondary center"
                            btnText="Continue to website"
                            onClick={this.props.onClick}
                        />
                    </ButtonContainer>
            </Modal>
        </React.Fragment>
        )
    }
}




export default ClosingModal;