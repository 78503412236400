import React from 'react';
import styled from 'styled-components';

const ToastContainer = styled.div`
    display: inline-block;
    background: #FFF5EB;
    position absolute;
    text-align: center;
    width: 820px;
    margin: 16px auto 0px auto;
    left: 0;
    right: 0;
    top: 120px;
    padding: 4px 16px;
    border: 1px solid #DC6204;
    border-radius: 4px;
    z-index: 10;
    box-shadow: 0 8px 16px rgb(0 0 0 / 5%), 0 10px 10px rgb(0 0 0 / 5%);

    & h3{
        color: #333;
        text-transform: uppercase;
        padding-top: 6px;
    }

    & p {
            font-weight 500;
            font-size: 16px;
            color: #666;
            margin-top: 0;
        }

    @media screen and (max-width: 850px) {
        width: 600px;
      }

    @media screen and (max-width: 625px) {
        width: 90%;
      }
`;

const Emoji = styled.span`
    font-size: 24px;
`;

const Toast = () => (
    <ToastContainer className='homepage'>
            <h3> ☀️ Annual Holidays ☀️ </h3>
            <p>Amorae will be closed on Wednesday 14th August for our annual holidays and we are reopening on 13th September.</p>
    </ToastContainer>
)




export default Toast;